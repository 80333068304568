
import { mapState } from 'vuex'
export default {
  data () {
    return {
      editable: false
    }
  },
  computed: {
    ...mapState(['auth'])
  },
  methods: {
    setEditableStatus (alias, redirectRoute, noRedirect, param) {
      this.editable = this.isPermissionActive(alias)
      if ((param ? this.$route.params[param] === 'new' : this.$route.params.id === 'new') && !this.editable && !noRedirect) {
        this.$router.push({ name: redirectRoute })
      }
    },
    isPermissionActive (alias) {
      return this.auth.credentials && ((this.auth.credentials.role.resources.findIndex((resource) => { return (resource.alias === 'ALL_RESOURCES') || resource.alias === alias }) > -1))
    },
    getResourceName (alias) {
      let group = alias.split('_')
      group.splice(0, 1)
      group = group.join('_')
      return this.$te(`roles.custom_resource_names.${alias}`)
        ? this.$t(`roles.custom_resource_names.${alias}`)
        : this.$te(`roles.resources.${alias.split('_')[0]}`) && this.$te(`roles.groups.${group}`)
          ? this.$t(`roles.resources.${alias.split('_')[0]}`) + ' ' + this.$t(`roles.groups.${group}`).toLowerCase()
          : null
    }
  }
}
